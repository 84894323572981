import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import subscriptionImage from './subscribe-to-updates.png';
import PlannedChangeList from './Plans';
import plansTestData from './plans-data-beta.json5';
import APIChangelogs from './APIChangelogs';
import NewsBlog from './NewsBlog';
import ChangelogSubscription from '~/components/ChangelogSubscription/ChangelogSubscription.tsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "news-and-notifications---test"
    }}>{`News and Notifications - Test`}</h1>
    <p><a parentName="p" {...{
        "href": "/pages-intro-news-beta"
      }}>{`Beta`}</a></p>
    <ChangelogSubscription subscriptionImage={<img src={subscriptionImage} alt="subscribe to updates" />} mdxType="ChangelogSubscription" />
    <PlannedChangeList plansData={plansTestData} mdxType="PlannedChangeList" />
    <APIChangelogs isTest={true} mdxType="APIChangelogs" />
    <NewsBlog mdxType="NewsBlog" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      